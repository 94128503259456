import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~6],
		"/(app)/account": [12,[2]],
		"/(app)/adjustments": [~13,[2]],
		"/(app)/adjustments/new": [~15,[2]],
		"/(app)/adjustments/[id]": [~14,[2]],
		"/(app)/(comms)/comms": [~7,[2,3]],
		"/(app)/contacts": [~16,[2]],
		"/(app)/contacts/new": [~18,[2]],
		"/(app)/contacts/[id]": [~17,[2]],
		"/(app)/curbside": [19,[2]],
		"/(app)/dashboard": [~20,[2]],
		"/(app)/dismissal-groups": [~21,[2]],
		"/(app)/dismissal-groups/new": [~23,[2]],
		"/(app)/dismissal-groups/[id]": [~22,[2]],
		"/(auth)/forgot-password": [~58,[5]],
		"/(app)/(comms)/groups": [~8,[2,3]],
		"/(app)/(comms)/groups/new": [~10,[2,3]],
		"/(app)/(comms)/groups/[id]": [~9,[2,3]],
		"/(auth)/login": [~59,[5]],
		"/(auth)/logout": [~60,[5]],
		"/(app)/operators": [~24,[2]],
		"/(app)/operators/new": [~26,[2]],
		"/(app)/operators/[id]": [~25,[2]],
		"/(app)/organizations": [~27,[2]],
		"/(app)/organizations/new": [~29,[2]],
		"/(app)/organizations/[id]": [~28,[2]],
		"/(app)/programs": [~30,[2]],
		"/(app)/programs/new": [~32,[2]],
		"/(app)/programs/[id]": [~31,[2]],
		"/(app)/queues": [33,[2]],
		"/(app)/reports": [34,[2]],
		"/(auth)/reset-password": [~61,[5]],
		"/(app)/route-imports": [35,[2]],
		"/(app)/routes": [~36,[2]],
		"/(app)/routes/new": [~41,[2]],
		"/(app)/routes/[id]": [~37,[2,4]],
		"/(app)/routes/[id]/operators": [~38,[2,4]],
		"/(app)/routes/[id]/schedule": [~39,[2,4]],
		"/(app)/routes/[id]/trips": [~40,[2,4]],
		"/(app)/stops": [~42,[2]],
		"/(app)/stops/new": [~44,[2]],
		"/(app)/stops/[id]": [~43,[2]],
		"/(app)/student-imports": [45,[2]],
		"/(app)/student-imports/new": [~46,[2]],
		"/(app)/students": [~47,[2]],
		"/(app)/students/new": [~49,[2]],
		"/(app)/students/[id]": [~48,[2]],
		"/(app)/(comms)/text-blasts": [11,[2,3]],
		"/(app)/track": [50,[2]],
		"/(app)/transports": [~51,[2]],
		"/(app)/transports/new": [~53,[2]],
		"/(app)/transports/[id]": [~52,[2]],
		"/(app)/trips": [54,[2]],
		"/(app)/users": [~55,[2]],
		"/(app)/users/new": [~57,[2]],
		"/(app)/users/[id]": [~56,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';